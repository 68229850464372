export const adminPrefix = '/admin73328793';

const routesAdmin = {

  home: `${adminPrefix}/`,
  dashboard: `${adminPrefix}/dashboard`,
  login: `${adminPrefix}/logowanie`,

  workshops: {
    main: `${adminPrefix}/warsztaty`,
  },

  users: {
    main: `${adminPrefix}/uzytkownicy`,
  },

  workshopPlanTransactions: `${adminPrefix}/lista-transakcji`,
};

export default routesAdmin;
